<template>
  <div class="content-container flex-row pb-0 pt-20">
    <TopLevelLeftNav />
    <div class="page">
      <div class="subpage-banner"></div>
      <div class="subpage-content">
        <section>
          <div class="content-band">
            <div class="pagehead-title">
              <h1>Icons</h1>
              <!-- <div class="pagehead-link">
                <a href=""
                  ><img src="/html-components/images/svg/link.svg" alt=""
                /></a>
              </div> -->
            </div>
            <p>
              Icons are visual elements used to call attention to actions or
              information and should be used strategically, infrequently, and
              only with labels or supporting text.
            </p>
          </div>
        </section>

        <div class="divider max-w1200"></div>

        <section>
          <div class="content-band">
            <p>
              Some icons have become ubiquitous and their meanings have become
              well known to users. In rare circumstances, universally recognized
              icons may be used without a label.
            </p>
            <p>
              State of Ohio icons are based on FontAwesome. The style of icon
              used is a minimal variation of solid and outlined.
            </p>

            <h2>Sizes</h2>

            <div class="even-cols max-w800 mtb-30 gap-10">
              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 16px;"
                    src="../../assets/images/SVG/cat-icon.svg"
                    alt="Cat icon 16 pixels"
                  />
                </div>
                <div tabindex="-1">16px</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 20px;"
                    src="../../assets/images/SVG/cat-icon.svg"
                    alt="Cat icon 20 pixels"
                  />
                </div>
                <div tabindex="-1">20px</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red ">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/cat-icon.svg"
                    alt="Cat icon 24 pixels"
                  />
                </div>
                <div tabindex="-1">24px</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 36px;"
                    src="../../assets/images/SVG/cat-icon.svg"
                    alt="Cat icon 36 pixels"
                  />
                </div>
                <div tabindex="-1">36px</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 48px;"
                    src="../../assets/images/SVG/cat-icon.svg"
                    alt="Cat icon 48 pixels"
                  />
                </div>
                <div tabindex="-1">48px</div>
              </div>
            </div>

            <div class="max-w800 p-30 pb-20 gray-250">
              <div class="font-red semibold">Icon Scalling</div>
              <p>
                We recommend the icons to be shown in either 16, 20, 24, 36 or
                48px. The default being 16 and 20px, the other three are used
                for special statement.
              </p>
            </div>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h2>Icon Font</h2>
            <p>
              Our system icons are based on FontAwesome. You can find them in
              our UXPin library or consult them on FontAwesome website.
            </p>
            <button class="button--black" @click="handleFAButtonClick">
              FontAwesome
            </button>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Common Icon Assets</h3>

            <div class="even-cols max-w800 mt-30 gap-10">
              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/search-icon.svg"
                    alt="Search icon"
                  />
                </div>
                <div tabindex="-1">Search</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/help-icon.svg"
                    alt="Help icon"
                  />
                </div>
                <div tabindex="-1">Help</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/profile-icon.svg"
                    alt="Profile icon"
                  />
                </div>
                <div tabindex="-1">Profile</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/edit-reg.svg"
                    alt="Edit icon"
                  />
                </div>
                <div tabindex="-1">Edit</div>
              </div>

              <div class="tile-sml hover-shadow white ptb-30 hover-font-red">
                <div>
                  <img
                    style="height: 24px;"
                    src="../../assets/images/SVG/external-link-reg.svg"
                    alt="External link icon"
                  />
                </div>
                <div tabindex="-1">External Link</div>
              </div>
            </div>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Alignment and Color</h3>
            <div>
              <div class="icon__container" style="margin-right:12px;">
                <div class="icon__info">
                  Search
                </div>
              </div>
              <p>Icons are center-aligned with text.</p>
              <div class="icon__container">
                <div class="icon__info"></div>
              </div>
              <p>Icons are single.</p>
            </div>
          </div>
        </section>

        <div class="divider"></div>

        <section>
          <div class="content-band">
            <h3>Accessibility</h3>
            <p>
              Icons are considered to be either semantic, that is they are used
              to convey an idea or information, or decorative, meaning they are
              used in conjunction with text to reinforce an idea. Screen readers
              handle these types of icons differently. Informational icons are
              read aloud by screen readers to users, while decorative icons are
              ignored.
            </p>

            <div class="flex-grid gap-10 mt-30 max-w800">
              <a
                href="https://webaim.org/resources/contrastchecker/"
                target="_blank"
                class="card card-sml white fluid"
              >
                <div class="card__img external icon red-700">
                  <img
                    src="../../assets/images/fa-icon.png"
                    alt="Contrast Chekcer"
                  />
                </div>
                <div class="card__content">
                  <h4 class="semibold">WebAIM Contrast Checker</h4>
                  <p>
                    Contrast and Color Accessibility
                  </p>
                </div>
              </a>

              <a
                href="https://www.w3.org/WAI/WCAG21/quickref/"
                target="_blank"
                class="card card-sml white fluid"
              >
                <div class="card__img icon external red-700">
                  <img src="../../assets/images/fa-access.png" alt="" />
                </div>
                <div class="card__content">
                  <h4 class="semibold">Font Awesome Accessibility</h4>
                  <p>
                    Information on accessibility
                  </p>
                </div>
              </a>
            </div>
          </div>
        </section>
      </div>

      <SubFooter />
    </div>
  </div>
</template>

<script>
import TopLevelLeftNav from "../../components/TopLevelLeftNav";
import SubFooter from "../../components/SubFooter";
export default {
  name: "Iconography",
  components: {
    TopLevelLeftNav,
    SubFooter,
  },
  methods: {
    handleFAButtonClick: function() {
      window.open("https://fontawesome.com/", "_blank");
    },
  },
};
</script>
